<script>
import { PolarArea } from 'vue-chartjs'

export default {
  extends: PolarArea,
  props: {
    data: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  watch: {
     
     'data.labels' (newValue) {
        this.renderChart(this.data, this.options);
    },
   },
  mounted() {
    this.renderChart(this.data, this.options)
  },
}
</script>
